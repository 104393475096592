import { motion } from 'framer-motion'
import { Button } from '~/components/ui/button'
import { Link } from '@remix-run/react'

export function Navigation() {
  return (
    <motion.header 
      className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="container flex h-16 items-center justify-between max-w-7xl mx-auto px-4">
        <Link to="/" className="flex items-center space-x-2">
          <img 
            src="/assets/LOGO-light.svg" 
            alt="Everliquid Logo" 
            className="h-8 w-auto"
          />
        </Link>

        <nav className="flex items-center gap-4">
          <Button variant="ghost" asChild>
            <Link to="/sign-in">Sign In</Link>
          </Button>
          <Button asChild>
            <Link to="/sign-up">Sign Up</Link>
          </Button>
        </nav>
      </div>
    </motion.header>
  )
} 